import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { UseStyles } from 'hooks';
import MuiTextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useMemberApi } from 'hooks/useMemberApi';
import MemberApi from 'services/api/member';
import { Autocomplete, DateField, RadioGroup, Select, TextField } from 'components/Form';
import { useStoreon } from 'storeon/react';
import ProfileFormInfo from './ProfileFormInfo';
import CircularProgress from '@mui/material/CircularProgress';
import { AxiosResponse } from 'axios';
import genderList from 'data/gender.json';
import honorificList from 'data/honorific.json';
import { filterEmptyData } from 'utils';
import { useTimezones } from 'hooks/useTimezones';
import * as z from 'zod';
import {
  MemberProfileInput,
  MemberProfileOutput,
  MemberProfileInputGenderEnum,
  MemberProfileInputHonorificEnum,
} from 'services/api/client';
import $account from 'services/Account';
import dayjs from 'dayjs';

const { zodResolver } = require('@hookform/resolvers/zod');

// type DeepNonNullable<T> = { [P in keyof T]-?: NonNullable<T[P]>; } & NonNullable<T>;
const requiredMessage = 'The field is required.';
const required = () => ({
  invalid_type_error: requiredMessage,
  required_error: requiredMessage,
});

const schema = (() => {
  return z
    .object({
      dob: z.string(required()).regex(/^[0-9]{4}-[0-9]{2}-[0-9]{2}$/, 'Wrong date format.'),
      gender: z.nativeEnum(MemberProfileInputGenderEnum, required()),
      gender_other: z.string().optional().nullable(),
      honorific: z.nativeEnum(MemberProfileInputHonorificEnum, required()).optional().nullable(),
      timezone: z.string(required()),
      military: z
        .object({
          rank: z.string().optional().nullable(),
        })
        .refine(({ rank }) => $account.roleHhm() || !!rank, {
          message: 'The field is required.',
          path: ['rank'],
        })
        .optional(),
    })
    .refine(({ gender, gender_other }) => 'other' !== gender || !!gender_other, {
      message: 'Other field is empty.',
      path: ['gender_other'],
    });
})();
type schemaArgs = keyof z.infer<typeof schema>;
type NonNullableFields<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};
type FormArgs = Required<
  NonNullableFields<
    Omit<
      MemberProfileInput,
      'address' | 'gender_other' | 'honorific' | 'military' | 'first_name' | 'last_name' | 'middle_name'
    >
  >
> &
  Pick<MemberProfileInput, 'gender_other'> & {
    military?: { rank?: string | null };
    honorific?: MemberProfileInputHonorificEnum | null;
  };

export function ProfileForm(): JSX.Element {
  const classes = UseStyles();
  const [preview, setPreview] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const { data, mutate, loading } = useMemberApi<MemberProfileOutput>('memberProfile');
  const { data: ranks } = useMemberApi<Data>('militaryRanks');
  const timezones = useTimezones(data?.timezone);
  const { dispatch } = useStoreon();
  const methods = useForm<FormArgs>({
    resolver: zodResolver(schema),
  });
  const {
    handleSubmit,
    reset,
    watch,
    setError,
    formState: { isDirty },
  } = methods;
  useEffect(() => {
    if (!loading && data) {
      reset(data as NonNullableFields<MemberProfileOutput> & { military: { rank?: string | null } });
    }
  }, [loading]);
  useEffect(() => {
    if (isDirty) {
      dispatch('context/set', ['formModified', true]);
    }
  }, [isDirty]);
  const gender = watch('gender');
  const onSubmit = async (data: Data) => {
    try {
      setSubmitting(true);
      const filteredData = filterEmptyData(data);
      const res = await MemberApi.memberProfileUpdate(filteredData as MemberProfileInput);
      mutate(res);
      setPreview(true);
      dispatch('context/set', ['formModified', false]);
    } catch (e) {
      const error = e as AxiosResponse<HttpResponseError>;
      if (!error.data) {
        throw e;
      }
      const { details: { field = '' } = {}, message = '' } = error.data.error ?? {};
      if (field) {
        setError(field.replace(/\./, '') as schemaArgs, {
          type: 'manual',
          message,
        });
      }
    }
    setSubmitting(false);
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {preview ? (
          <>
            <ProfileFormInfo />
            <div className={classes.accountProfileActions}>
              <Button onClick={() => setPreview(false)} size='small' color='primary' variant='contained'>
                Edit Details
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className={classes.row}>
              <div className={`${classes.col} ${classes.colOneFourth}`}>
                <DateField label='Birthdate' name='dob' format='MM/DD/YYYY' formatFrom='YYYY-MM-DD' />
              </div>
              <div className={`${classes.col} ${classes.colOneFourth}`}>
                <Select
                  label={
                    <>
                      Honorific <span>(optional)</span>
                    </>
                  }
                  name='honorific'
                  options={honorificList}
                />
              </div>
              <div className={`${classes.col} ${classes.colOneFourth}`}>
                <div className={classes.accountFieldWrapper}>
                  <label className={classes.accountFieldLabel}>NATIONALITY</label>
                  <MuiTextField fullWidth disabled name='nationality' variant='outlined' value='USA' />
                </div>
              </div>
              <div className={`${classes.col} ${classes.colOneFourth}`}>
                {!!timezones && <Autocomplete label='Time Zone' name='timezone' options={timezones} />}
              </div>
            </div>
            <div className={classes.row}>
              {!$account.roleHhm() && (
                <div className={`${classes.col} ${classes.colOneFourth}`}>
                  <Select
                    label='Rank'
                    name='military.rank'
                    options={Object.keys(ranks || {}).map((key: string) => ({ id: key, name: ranks![key] }))}
                  />
                </div>
              )}
              <div className={`${classes.col} ${classes.colOneHalf}`}>
                <RadioGroup label='Gender' name='gender' options={genderList} />
              </div>
              <div className={`${classes.col} ${$account.roleHhm() ? classes.colOneHalf : classes.colOneFourth}`}>
                {'other' === gender && (
                  <TextField
                    label='&nbsp;'
                    name='gender_other'
                    fullWidth
                    placeholder='Provide your gender that is not male/female'
                  />
                )}
              </div>
            </div>
            <div className={classes.accountProfileActions}>
              <Button disabled={submitting} type='submit' size='small' color='primary' variant='contained'>
                Save Updates
                {submitting && <CircularProgress size={24} />}
              </Button>
            </div>
          </>
        )}
      </form>
    </FormProvider>
  );
}

export default ProfileForm;
