import React from 'react';
import { UseStyles } from 'hooks';
import { useMemberApi } from 'hooks/useMemberApi';
import MemberApi from 'services/api/member';
import { MemberProfileOutput } from 'services/api/client';
import { stateForces, states } from 'utils/helpers/helpers';

export function ShippingFormInfo(): JSX.Element {
  const classes = UseStyles();
  const { data, loading } = useMemberApi<MemberProfileOutput>('memberProfile');
  const type = data?.address?.type;
  const addressStates = 'home' === type ? states : stateForces;
  return (
    <>
      <div className={classes.row}>
        <div className={`${classes.col} ${classes.colOneHalf}`}>
          <div className={classes.accountFieldWrapper}>
            <label className={classes.accountFieldLabel}>Address line 1</label>
            <div className={classes.accountFieldPseudo}>{data?.address?.line1}</div>
          </div>
        </div>
        <div className={`${classes.col} ${classes.colOneHalf}`}>
          <div className={classes.accountFieldWrapper}>
            <label className={classes.accountFieldLabel}>
              {'home' === type ? 'Address line 2' : 'Apt, floor, suite, etc.'}
            </label>
            <div className={classes.accountFieldPseudo}>{data?.address?.line2}</div>
          </div>
        </div>
      </div>
      <div className={classes.row}>
        <div className={`${classes.col} ${classes.colOneHalf}`}>
          <div className={classes.accountFieldWrapper}>
            <label className={classes.accountFieldLabel}>{'home' === type ? 'City' : 'APO/FPO/DPO'}</label>
            <div className={classes.accountFieldPseudo}>{data?.address?.city}</div>
          </div>
        </div>
        <div className={`${classes.col} ${classes.colOneFourth}`}>
          <div className={classes.accountFieldWrapper}>
            <label className={classes.accountFieldLabel}>{'home' === type ? 'State' : 'Region'}</label>
            <div className={classes.accountFieldPseudo}>
              {!!data?.address?.state && addressStates[data.address.state as keyof typeof addressStates]}
            </div>
          </div>
        </div>
        <div className={`${classes.col} ${classes.colOneFourth}`}>
          <div className={classes.accountFieldWrapper}>
            <label className={classes.accountFieldLabel}>Zip Code</label>
            <div className={classes.accountFieldPseudo}>{data?.address?.postal_code}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ShippingFormInfo;
