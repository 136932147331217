import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { UseStyles } from 'hooks';
import Button from '@mui/material/Button';
import { useMemberApi } from 'hooks/useMemberApi';
import MemberApi from 'services/api/member';
import { RadioGroup, Select, TextField } from 'components/Form';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { AddressAutocomplete } from 'containers/Form/AddressAutocomplete';
import { AxiosResponse } from 'axios';
import ShippingFormInfo from './ShippingFormInfo';
import { useStoreon } from 'storeon/react';
import { stateForces, states } from 'utils/helpers/helpers';
import * as z from 'zod';
import $account from 'services/Account';
import {
  MemberProfileInput,
  MemberProfileOutput,
  AddressInput,
  AddressOutput,
  AddressInputTypeEnum,
} from 'services/api/client';

const { zodResolver } = require('@hookform/resolvers/zod');

const requiredMessage = 'The field is required.';
const required = () => ({
  invalid_type_error: requiredMessage,
  required_error: requiredMessage,
});
const schema = z.object({
  address: z.object({
    city: z.string(required()).min(1, { message: requiredMessage }),
    line1: z.string(required()).min(1, { message: requiredMessage }),
    line2: z.string().optional().nullable(),
    postal_code: z.string(required()).min(1, { message: requiredMessage }),
    state: z.string(required()).min(1, { message: requiredMessage }),
    type: z.nativeEnum(AddressInputTypeEnum, required()),
  }),
});

type FormArgs = {
  address: Required<Omit<AddressInput, 'country' | 'line2'>> & Pick<AddressInput, 'line2'>;
};

export function ShippingForm(): JSX.Element {
  const classes = UseStyles();
  const [preview, setPreview] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const { dispatch } = useStoreon();
  const { data, mutate, loading } = useMemberApi<MemberProfileOutput & { address: AddressOutput }>('memberProfile');
  const methods = useForm<FormArgs>({
    resolver: zodResolver(schema),
  });
  const {
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { isDirty },
    setError,
  } = methods;
  const type = watch('address.type');
  const addressStates = 'home' === type ? states : stateForces;
  useEffect(() => {
    if (!loading && data) {
      reset(data);
    }
  }, [loading]);
  useEffect(() => {
    if (isDirty) {
      dispatch('context/set', ['formModified', true]);
    }
  }, [isDirty]);
  const onSubmit = async (data: Data) => {
    try {
      setSubmitting(true);
      const payload = data as MemberProfileInput;
      if (payload?.address && !payload.address.line2) {
        payload.address.line2 = null;
      }
      const res = await MemberApi.memberProfileUpdate(payload);
      mutate(res);
      setPreview(true);
      dispatch('context/set', ['formModified', false]);
    } catch (e) {
      console.log({ e });
      const error = e as AxiosResponse<HttpResponseError>;
      if (!error.data) {
        throw e;
      }
      const { details: { field = '' } = {}, message = '' } = error.data.error ?? {};
      if (field) {
        setError(field.replace(/\./, ''), {
          type: 'manual',
          message,
        });
      }
    }
    setSubmitting(false);
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography className={classes.accountProfileSubTitle}>Shipping Address</Typography>
        <RadioGroup
          disabled={preview}
          name='address.type'
          options={[
            { id: 'post', name: 'APO/FPO/DPO' },
            { id: 'home', name: 'Home.Office Address' },
          ]}
        />
        {preview || $account.roleHhm() ? (
          <>
            <ShippingFormInfo />
            {!$account.roleHhm() && (
              <div className={classes.accountProfileActions}>
                <Button onClick={() => setPreview(false)} size='small' color='primary' variant='contained'>
                  Edit Shipping Address
                </Button>
              </div>
            )}
          </>
        ) : (
          <>
            <div className={classes.row}>
              <div className={`${classes.col} ${classes.colOneHalf}`}>
                <AddressAutocomplete
                  label='Address line 1'
                  onHiddenChange={(input) => {
                    if ('home' === type) {
                      input.value = getValues('address.line1') || data?.address?.line1 || '';
                    }
                  }}
                  hidden={!type || 'post' === type}
                  name='address.line1'
                />
                <TextField fullWidth hidden={'home' === type} label='Address line 1' name='address.line1' />
              </div>
              <div className={`${classes.col} ${classes.colOneHalf}`}>
                <TextField
                  fullWidth
                  label={
                    'home' === type ? (
                      <>
                        Address line 2 <span>(optional)</span>
                      </>
                    ) : (
                      <>
                        Apt, floor, suite, etc. <span>(optional)</span>
                      </>
                    )
                  }
                  name='address.line2'
                />
              </div>
            </div>
            <div className={classes.row}>
              <div className={`${classes.col} ${classes.colOneHalf}`}>
                {'home' === type ? (
                  <TextField fullWidth label='City' name='address.city' />
                ) : (
                  <Select
                    label='APO/FPO/DPO'
                    name='address.city'
                    options={[
                      { id: 'APO', name: 'APO' },
                      { id: 'FPO', name: 'FPO' },
                      { id: 'DPO', name: 'DPO' },
                    ]}
                  />
                )}
              </div>
              <div className={`${classes.col} ${classes.colOneFourth}`}>
                <Select
                  label={'home' === type ? 'State' : 'Region'}
                  name='address.state'
                  options={Object.keys(addressStates).map((key: string) => ({
                    id: key,
                    name: addressStates[key as keyof typeof addressStates],
                  }))}
                />
              </div>
              <div className={`${classes.col} ${classes.colOneFourth}`}>
                <TextField fullWidth label='ZIP Code' name='address.postal_code' />
              </div>
            </div>
            <div className={classes.accountProfileActions}>
              <Button disabled={submitting} type='submit' size='small' color='primary' variant='contained'>
                Save Updates
                {submitting && <CircularProgress size={24} />}
              </Button>
            </div>
          </>
        )}
      </form>
    </FormProvider>
  );
}

export default ShippingForm;
