import React from 'react';
import { UseStyles } from 'hooks';
import { useMemberApi } from 'hooks/useMemberApi';
import { MemberProfileOutput } from 'services/api/client';
import gender from 'data/gender.json';
import honorific from 'data/honorific.json';
import $account from 'services/Account';
import { useTimezones } from 'hooks/useTimezones';
import dayjs from 'dayjs';

export function ProfileFormInfo(): JSX.Element {
  const classes = UseStyles();
  const { data, loading } = useMemberApi<MemberProfileOutput>('memberProfile');
  const timezones = useTimezones(data?.timezone);
  const { data: ranks } = useMemberApi<Data>('militaryRanks');
  return (
    <>
      <div className={classes.row}>
        <div className={`${classes.col} ${classes.colOneFourth}`}>
          <div className={classes.accountFieldWrapper}>
            <label className={classes.accountFieldLabel}>Birthdate</label>
            <div className={classes.accountFieldPseudo}>
              {data?.dob ? dayjs(data.dob, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''}
            </div>
          </div>
        </div>
        <div className={`${classes.col} ${classes.colOneFourth}`}>
          <div className={classes.accountFieldWrapper}>
            <label className={classes.accountFieldLabel}>Honorific</label>
            <div className={classes.accountFieldPseudo}>
              {!!data?.honorific && honorific.find((item) => item.id === data.honorific)?.name}
            </div>
          </div>
        </div>
        <div className={`${classes.col} ${classes.colOneFourth}`}>
          <div className={classes.accountFieldWrapper}>
            <label className={classes.accountFieldLabel}>NATIONALITY</label>
            <div className={classes.accountFieldPseudo}>USA</div>
          </div>
        </div>
        <div className={`${classes.col} ${classes.colOneFourth}`}>
          <div className={classes.accountFieldWrapper}>
            <label className={classes.accountFieldLabel}>Time Zone</label>
            <div className={classes.accountFieldPseudo}>
              {data?.timezone ? timezones.find((zone) => data.timezone === zone.id)?.name : null}
            </div>
          </div>
        </div>
      </div>
      <div className={`${classes.row} ${classes.rowJustifyStart}`}>
        {!$account.roleHhm() && (
          <>
            <div className={`${classes.col} ${classes.colOneFourth}`}>
              <div className={classes.accountFieldWrapper}>
                <label className={classes.accountFieldLabel}>Rank</label>
                <div className={classes.accountFieldPseudo}>
                  {!!data?.military.rank && !!ranks && ranks[data.military.rank]}
                </div>
              </div>
            </div>
          </>
        )}
        <div className={`${classes.col} ${classes.colOneFourth}`}>
          <div className={classes.accountFieldWrapper}>
            <label className={classes.accountFieldLabel}>Gender</label>
            <div className={classes.accountFieldPseudo}>
              {!!data?.gender &&
                ('other' === data.gender ? data.gender_other : gender.find((item) => item.id === data.gender)?.name)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileFormInfo;
