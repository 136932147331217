import React, { useState, useRef, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { UseStyles } from 'hooks';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import MemberApi from 'services/api/member';
import CircularProgress from '@mui/material/CircularProgress';
import { useStoreon } from 'storeon/react';
import { MemberProfilePhotoInput, MemberProfilePhotoUploadOutput } from 'services/api/client';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { WarnCircleIcon } from 'theme/icons';

export type AssetObject = {
  name?: string;
  preview?: string;
  error?: 'size' | 'dimensions' | 'format';
};

type Props = {
  image: AssetObject | null;
  onClose: (url?: string) => void;
  uploadDesktopFiles: () => void;
};

function CropInfo({ submitting, handleConfirm }: { submitting: boolean; handleConfirm: () => void }): JSX.Element {
  const classes = UseStyles();
  const {
    context: { cropData },
  } = useStoreon('context');
  const height = Math.floor(cropData?.height || 0);
  const width = Math.floor(cropData?.width || 0);
  const dimensionsError = width < 208 || height < 208;
  return (
    <>
      <div className={classes.accountProfileAvatarDialogImgInfo}>
        <div>
          Crop Box Size:{' '}
          <strong>
            {width} / {height}
          </strong>
        </div>
        {dimensionsError && !!cropData && <span>Crop dimensions should be more than 208 / 208.</span>}
      </div>
      <Button disabled={submitting || dimensionsError} onClick={handleConfirm} color='primary' variant='outlined'>
        Save Edits
        {submitting && <CircularProgress size={24} />}
      </Button>
    </>
  );
}

export function ProfilePhotoPopup(props: Props): JSX.Element {
  const classes = UseStyles();
  const { image, onClose, uploadDesktopFiles } = props;
  const [photo, setPhoto] = useState<string | undefined>();
  const [submitting, setSubmitting] = useState(false);
  const { dispatch } = useStoreon();
  const cropperRef = useRef<HTMLImageElement>(null);
  const [cropperData, setCropperData] = useState<undefined | Data>();
  const onCrop = (event: any) => {
    const { width, height } = event.detail;
    dispatch('context/set', ['cropData', { width, height }]);
  };
  useEffect(() => {
    (async () => {
      if (image?.preview) {
        setPhoto(image.preview);
      } else if (image && !image.error) {
        const res = (await MemberApi.memberProfilePhotoInfo()) as unknown as MemberProfilePhotoInput;
        if (res.options) {
          const { left = 0, top = 0, height = 0, width = 0 } = res.options;
          setCropperData({
            x: left,
            y: top,
            height,
            width,
          });
        }
        if (res.file) {
          setPhoto(`data:image/png;base64,${res.file}`);
        }
      } else if (!image) {
        setPhoto(undefined);
      }
    })();
  }, [image]);
  const handleConfirm = async () => {
    try {
      setSubmitting(true);
      const imageElement: any = cropperRef?.current;
      const cropper: any = imageElement?.cropper;
      const { x, y, width, height } = cropper.getData();
      const res = (await MemberApi.memberProfilePhotoUpload({
        file: photo!.replace(/^.+base64,/, ''),
        options: {
          left: Math.floor(x),
          top: Math.floor(y),
          width: Math.floor(width),
          height: Math.floor(height),
        },
      })) as unknown as MemberProfilePhotoUploadOutput;
      onClose(`/files/member/profile-photo/${res.token}.png`);
    } catch (e) {
      console.log({ e });
      dispatch('message/error', 'Error occured during photo upload. Please try again.');
    }
    setSubmitting(false);
    onClose();
  };
  return (
    <Dialog
      scroll='body'
      open={!!image}
      onClose={() => onClose()}
      fullWidth
      maxWidth={false}
      classes={{ paper: classes.accountDialog }}
      aria-labelledby='customized-dialog-title'
    >
      <style>{`
        .cropper-view-box {
          box-shadow: 0 0 0 1px #39f;
          border-radius: 50%;
          outline: 0;
        }
        .cropper-face {
          background-color:inherit !important;
        }
        .cropper-view-box {
          outline:inherit !important;
        }
      `}</style>
      <div className={classes.accountDialogHeader} id='customized-dialog-title'>
        <IconButton className={classes.accountDialogHeaderClose} onClick={() => onClose()}>
          <CloseIcon className='icon' />
        </IconButton>
        <div className={classes.accountDialogTitle}>Edit Photo</div>
      </div>
      <DialogContent className={classes.accountDialogContent}>
        <div>
          {!photo && !image?.error && (
            <div className={classes.accountPaymentListLoader}>
              <CircularProgress className='icon' />
            </div>
          )}
          {'dimensions' === image?.error && (
            <div className={classes.accountDialogContentStatus}>
              <WarnCircleIcon className='icon warn' />
              <div className={classes.accountDialogContentStatusTitle}>
                Your photo width or height should be more than 208px.
              </div>
            </div>
          )}
          {'size' === image?.error && (
            <div className={classes.accountDialogContentStatus}>
              <WarnCircleIcon className='icon warn' />
              <div className={classes.accountDialogContentStatusTitle}>
                Your photo is larger than 2 MB. <br />
                Please choose a smaller photo.
              </div>
              <div className={classes.accountDialogContentStatusSubTitle}>JPG, GIF or PNG. Max size of 2 MB.</div>
            </div>
          )}
          {!image?.error && !!photo && (
            <Cropper
              src={photo}
              style={{ height: 400 }}
              aspectRatio={1}
              cropBoxResizable={true}
              viewMode={2}
              guides={false}
              crop={onCrop}
              ref={cropperRef}
              data={cropperData}
            />
          )}
        </div>
      </DialogContent>
      <DialogActions className={classes.accountDialogActions} disableSpacing>
        {image?.error ? (
          <>
            <Button
              color='primary'
              fullWidth
              onClick={(e: React.MouseEvent) => {
                e.preventDefault();
                uploadDesktopFiles();
              }}
              variant='outlined'
            >
              Upload New Photo
            </Button>
            <Button color='primary' variant='contained' onClick={() => onClose()}>
              Cancel
            </Button>
          </>
        ) : (
          <CropInfo submitting={submitting} handleConfirm={handleConfirm} />
        )}
      </DialogActions>
    </Dialog>
  );
}
