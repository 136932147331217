import type { NextPage } from 'next';
import React from 'react';
import { AccountLayout } from '@components/Account/AccountLayout';
import { UseStyles } from 'hooks';
import { ProfileInfo, ProfileForm, ShippingForm } from 'views/account/profile';

const Profile: NextPage = () => {
  const classes = UseStyles();
  return (
    <AccountLayout title='Manage Account'>
      <>
        <ProfileInfo />
        <div className={classes.accountProfileDivider} />
        <ProfileForm />
        <div className={classes.accountProfileDivider} />
        <ShippingForm />
      </>
    </AccountLayout>
  );
};

export default Profile;
