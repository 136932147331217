import React, { useCallback, useState } from 'react';
import { UseStyles } from 'hooks';
import Button from '@mui/material/Button';
import { useMemberApi } from 'hooks/useMemberApi';
import MemberApi from 'services/api/member';
import PersonIcon from '@mui/icons-material/Person';
import { MemberProfileOutput } from 'services/api/client';
import { useDropzone } from 'react-dropzone';
import { ProfilePhotoPopup } from './';
import { ConfirmDialog } from 'containers/Dialog/ConfirmDialog';
import { AssetObject } from './ProfilePhotoPopup';
import { CheckCircleIcon } from 'theme/icons';
import { useStoreon } from 'storeon/react';
import { Kustomer } from 'containers/Widget/Kustomer';
import withPathPrefix from 'utils/withPathPrefix';
import { useRouter } from 'next/router';
import Config from 'services/Config';
import { Account } from 'services';
import ProfileNameForm from './ProfileNameForm';
import { ensureUrl } from 'utils';

export function ProfileInfo(): JSX.Element {
  const classes = UseStyles();
  const [editShown, setEditShown] = useState(false);
  const { dispatch } = useStoreon();
  const router = useRouter();
  const { data, mutate } = useMemberApi<MemberProfileOutput>('memberProfile');
  const [image, setImage] = useState<AssetObject | null>(null);
  const handleClosePopup = (croppedUrl?: string) => {
    setImage(null);
    if (croppedUrl) {
      mutate({ ...data, profile_photo: croppedUrl }, false);
      dispatch('message/open', 'The profile photo has been updated.');
    }
  };
  const deletePhoto = async () => dispatch('dialog/open', { id: 'profilePhotoDelete' });
  const editPhoto = async () => setImage({});
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      setImage({
        error: 'size',
      });
    }
    acceptedFiles.forEach((file: File) => {
      const reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = async (e) => {
        const img = new Image();
        img.onload = function () {
          if (img.width < 208 || img.height < 208) {
            setImage((image) => ({
              ...image,
              error: 'dimensions',
            }));
          }
        };
        img.src = reader.result as string;
        setImage({
          name: file.name,
          preview: reader.result as string,
        });
      };
      reader.readAsDataURL(file);
    });
  }, []);
  const {
    getRootProps,
    getInputProps,
    open: uploadDesktopFiles,
  } = useDropzone({
    accept: 'image/*',
    maxSize: 2097152,
    noClick: true,
    multiple: false,
    onDrop,
  });
  return (
    <div className={classes.row}>
      <div className={`${classes.col} ${classes.colOneFourth}`} {...getRootProps()}>
        <div className={classes.accountFieldWrapper}>
          <label className={classes.accountFieldLabel}>Profile Photo</label>
          <div className={classes.accountProfileAvatarWrapper}>
            <div
              className={classes.accountProfileAvatar}
              onMouseEnter={() => setEditShown(true)}
              onMouseLeave={() => setEditShown(false)}
            >
              {data?.profile_photo ? (
                <img
                  width='100%'
                  src={(ensureUrl(Config.getSync('api_base_url')) || withPathPrefix('/api')) + data.profile_photo}
                  className={classes.accountProfileAvatarImg}
                />
              ) : (
                <PersonIcon className={classes.accountProfileAvatarIcon} />
              )}
              {!!data?.profile_photo && editShown && (
                <Button
                  color='primary'
                  fullWidth
                  disableRipple
                  onClick={editPhoto}
                  className={classes.accountProfileAvatarBtnEdit}
                >
                  Edit Photo
                </Button>
              )}
            </div>
            <div className={classes.accountProfileAvatarBtns}>
              <input {...getInputProps()} />
              <Button
                color='primary'
                fullWidth
                onClick={(e: React.MouseEvent) => {
                  e.preventDefault();
                  uploadDesktopFiles();
                }}
                size='small'
                variant='outlined'
              >
                {data?.profile_photo ? 'Change Photo' : 'Upload Photo'}
              </Button>
              {!!data?.profile_photo && (
                <Button fullWidth onClick={deletePhoto}>
                  Delete Photo
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={`${classes.col} ${classes.colThreeFourth}`}>
        <div className={classes.accountProfileSubTitle}>Personal Information</div>
        <ProfileNameForm />
        {!Account.roleAdmin() && (
          <div className={`${classes.accountFieldHelp} ${classes.accountProfileInfoHelp}`}>
            <Kustomer
              buttonText='contact us'
              prefixButtonText='Please'
              suffixButtonText="if you've had a name change"
            />
          </div>
        )}
        <div className={classes.row}>
          <div className={`${classes.col} ${classes.colOneThird}`}>
            <div className={classes.accountProfileInfo}>
              <div className={classes.accountProfileInfoLabel}>Phone number:</div>
              <div className={classes.accountProfileInfoValue}>
                {data?.phone
                  ? data.phone.replace(/^\+?([0-9]{1})([0-9]{3})([0-9]{3})([0-9]+)$/, '+$1 ($2) $3 $4')
                  : '-'}
                {data?.verification?.phone && <CheckCircleIcon className='icon' />}
              </div>
              <Button onClick={() => router.push('/login')} className={classes.accountProfileInfoBtn}>
                Change Phone
              </Button>
            </div>
          </div>
          <div className={`${classes.col} ${classes.colTwoThird}`}>
            <div className={classes.accountProfileInfo}>
              <div className={classes.accountProfileInfoLabel}>Email address:</div>
              <div className={classes.accountProfileInfoValue}>
                {data?.email || '-'}
                {data?.verification?.email && <CheckCircleIcon className='icon' />}
              </div>
              <Button onClick={() => router.push('/login')} className={classes.accountProfileInfoBtn}>
                Change Email
              </Button>
            </div>
          </div>
        </div>
      </div>
      <ProfilePhotoPopup image={image} onClose={handleClosePopup} uploadDesktopFiles={uploadDesktopFiles} />
      <ConfirmDialog
        name='profilePhotoDelete'
        title='Delete Profile Photo'
        description='Are you sure you want to delete the profile photo?'
        handle={async () => await MemberApi.memberProfilePhotoDelete()}
        onSuccess={async () => mutate({ ...data, profile_photo: null }, false)}
      />
    </div>
  );
}

export default ProfileInfo;
